import { Klass } from '@/lib/transforms'

export class Seller {
  id: number
  name: string
  email: string

  @Klass(Date) created_at: Date
  @Klass(Date) updated_at: Date
}
