import { Decimal } from "@/lib/transforms"

export class Plan {
  id: number
  name: string
  code: string

  @Decimal() comm1: Big
  @Decimal() comm2: Big
}
