import { Klass } from '@/lib/transforms'
import { Store } from './Store'

export class Employee {
  id: number
  name: string
  email: string
  store_id: number

  @Klass(Store) store: Store
}
