import { Klass } from '@/lib/transforms'

export class Exportation {
  id: number

  token: string
  state: string
  file_url?: string
  file_name?: string
  message?: JSON

  @Klass(Date) created_at: Date
  @Klass(Date) updated_at: Date
}
