import { Klass } from '@/lib/transforms'
import { Seller } from './Seller'
import { Area } from './Area'
import { Plan } from './Plan'

export class Hotel {
  id: number
  name: string
  address: string
  type: string
  memo?: string
  seller_id: number
  entry_url: string

  state: string

  phone?: string
  email?: string
  fullname?: string
  ic_number?: string
  bank_name?: string
  account_no?: string

  @Klass(Seller) seller: Seller
  @Klass(Area) area: Area
  @Klass(Plan) plan: Plan

  @Klass(Date) created_at: Date
  @Klass(Date) updated_at: Date
}
