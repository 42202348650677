import { Klass, Decimal } from '@/lib/transforms'
import { Store } from './Store'

export class Car {
  id: number
  name: string
  category: string
  state: string
  content: string
  content_html: string
  @Decimal() price: Big

  @Klass(Store) store: Store
}
