import { Klass, Decimal } from '@/lib/transforms'
import { User } from './User'
import { Hotel } from './Hotel'
import { Store } from './Store'
import { Car } from './Car'

export class Order {
  id: number
  token: string
  state: string
  type: string

  phone: string
  fullname: string
  nric_no_or_passport_no?: string
  license_no?: string
  address?: string
  license_images: string[] = []

  vehicle_no?: string
  vehicle_image_url?: string
  deposit_id?: string
  rent_fee_id?: string
  @Klass(Date) rent_at?: Date
  @Klass(Date) finished_at?: Date

  payment_mode?: string

  @Klass(User) user: User
  @Klass(Hotel) hotel?: Hotel
  @Klass(Store) store: Store
  @Klass(Car) car: Car

  rent_days: number
  @Klass(Date) pick_date: Date

  @Decimal() price: Big
  @Decimal() total_price?: Big
  @Decimal() rent_fee?: Big
  @Decimal() deposit?: Big
  @Decimal() comm1_price?: Big
  @Decimal() comm2_price?: Big

  @Klass(Date) return_date: Date
  rent_name?: string
  employee_name?: string

  @Klass(Date) created_at: Date
  @Klass(Date) updated_at: Date
}
