import { Klass } from '@/lib/transforms'
import { Area } from './Area'

export class Store {
  id: number
  name: string
  phone: string
  address: string
  memo?: string

  custom_service_name?: string
  custom_service_tel?: string
  map_link?: string

  logo_url?: string

  entry_url: string

  @Klass(Area) area: Area

  @Klass(Date) created_at: Date
  @Klass(Date) updated_at: Date
}
