export class Area {
  id: number
  name: string
  city: string
  promo_pic_url: string

 fullname() {
    return `${this.name} - ${this.city}`
  }
}
